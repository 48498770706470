if(typeof axios !== "undefined") {
    let numberOfAjaxCAllPending = 0;
    // Add a request interceptor
    axios.interceptors.request.use(function (config) {
        numberOfAjaxCAllPending++;
        Pace.start()

        return config;
    }, function (error) {
        return Promise.reject(error);
    });

    // Add a response interceptor
    axios.interceptors.response.use(function (response) {
        numberOfAjaxCAllPending--;
        // Do something with response data
        if (numberOfAjaxCAllPending === 0) {
            Pace.stop()
        }
        return response;
    }, function (error) {
        numberOfAjaxCAllPending--;
        if (numberOfAjaxCAllPending === 0) {
            Pace.stop()
        }
        return Promise.reject(error);
    });
}
